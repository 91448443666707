/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";

.router-wrapper {
  position: relative;
  overflow: hidden;
  width: 100vw;
  height: calc(100% - 47px);
  perspective: 1200px;
  transform-style: preserve-3d;
}

:host {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: #60d7a9;
}

.daterangepicker.ltr {
  background-color: var(--light-bg) !important;
}

.daterangepicker.ltr tr td.active {
  background-color: var(--theme-deafult) !important;
}

.custom-toastr-top {
  top: 1em;
  right: 1em;
  z-index: 9999;
  position: fixed;
}
.custom-toastr-botton {
  bottom: 1em;
  right: 1em;
  z-index: 9999;
  position: fixed;
  margin-bottom: 10px;
}

.chartjs-principal-container {
  min-height: 250px;

  .chartjs-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.card-c-holder {
  grid-column-gap: 1rem;
  grid-row-gap: 0.2rem;
  background: var(--background);
  border-radius: 1rem;
  display: grid;
  font: 600 1.6rem / 2.2rem var(--font-family);
  grid-template-columns: 1fr 1fr;
  padding: 1.4rem 0.3rem;
  position: relative;
  width: 100%;
}

.card-c-holder .card-list-col {
  overflow: hidden;
}

.geneKeyValue {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.geneKeyValue__label {
  color: #3c4043b3;
  flex: 1 1;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 0 1rem;
  max-width: 100%;
  width: 100%;
}

.geneKeyValue__value {
  font-size: 14px;
  // font-weight: 600;
  line-height: 24px;
  margin: 0 1rem;
  max-width: 100%;
  width: 100%;
}

.card-more-button-holder {
  left: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35px;
  background: var(--white);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 35px;
  border: 1px solid var(--chart-border);
  cursor: pointer;
}

.custom-card {
  padding-left: 0px !important;
  padding-right: 0px !important;
  border: 1px solid var(--chart-border);
  border-radius: 12px;
  padding-top: 0px !important;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fondo gris translúcido */
  z-index: 999;
  /* Z-index inferior al del menú para que el menú esté por encima */
}

.popover1 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 70%;
  background-color: var(--white);
  border-top: 1px solid #ccc;
  z-index: 1000;
  animation: slideUp 0.3s ease;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    /* Comienza arriba de la pantalla */
  }

  to {
    transform: translateY(0);
    /* Desliza hacia abajo */
  }
}

.popover1 ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.popover1 ul li {
  padding: 10px;
  width: 100%;
  transition: background-color 0.3s ease;
}

.popover1 ul li:hover {
  background-color: rgba(115, 102, 255, 0.07);
  /* Cambiar el color de fondo al hacer hover */
}

.submenu-content {
  padding: 0 0 50px 20px;
  overflow-y: auto;
  max-height: 60vh;
  // height: 270px;
  // padding: 0 0 20px 20px;
  /* Estilos adicionales para el contenido del submenu */
}

.card-expand-button {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 35px;
  background-color: var(--white);
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  height: 35px;
  border: 1px solid var(--chart-border);
  cursor: pointer;
}

.custom-body-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.custom-placeholder {
  color: #999;
  font-size: 14px;
}

.card-header-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-radius: 10px;
}

.status-active {
  background-color: #54ba4a;
  color: white;
  border-radius: 5px;
  padding: 2px 5px;
}

.btn-spacing {
  margin-right: 15px;
}

.btn-head-table {
  padding: 0.375rem 0.75rem;
  background-color: var(--primary-button-color);
  color: var(--primary-button-text-color);
  border: none;
}

.btn-refresh {
  padding: 0.375rem 0.75rem;
  background-color: var(--refresh-button-color) !important;
  color: var(--primary-button-text-color) !important;
  border: none;
}

.btn-create {
  background-color: var(--primary-button-color);
  color: var(--primary-button-text-color);
  border: none;
}

.btn-cancel {
  background-color: var(--cancel-button-color) !important;
  color: var(--primary-button-text-color) !important;
  border: none;
}

.btn-head,
.btn-restore {
  background-color: var(--primary-button-color) !important;
  color: var(--primary-button-text-color) !important;
}
.btn-restore {
  background-color: var(--disabled-button-text-color) !important;
}

.btn-custom-red {
  background-color: red !important;
}

.card-header .btn-link:hover {
  color: inherit;
  text-decoration: none;
}

.card-header .btn-link {
  color: inherit;
  text-decoration: none;
}

.button-container {
  display: flex;
  justify-content: space-between;
}

.btn-approve-swal {
  background-color: #28a745;
  width: 45%;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-reject-swal {
  background-color: #dc3545;
  width: 45%;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.btn-approve-swal i,
.btn-reject-swal i {
  margin-right: 8px;
}

.suggestions-list {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin-top: 5px;
  list-style-type: none;
}

.suggestions-list li {
  padding: 5px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: var(--option-list-shadow);
}

.badge {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

.badge-active {
  background-color: #28a745;
}

.badge-passive {
  background-color: #dc3545;
}

.badge-equity {
  background-color: #007bff;
}

.badge-income {
  background-color: #ffca28;
}

.badge-cost {
  background-color: #6c757d;
}

.badge-expense {
  background-color: #e83e8c;
}

.datetime-custom {
  background-color: #f8f9fa;
  border-radius: 4px;
  color: #495057;
  transition: border-color 0.3s ease;
}

.datetime-custom:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 5px rgba(91, 192, 222);
}
.custom-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem; 
}